import React from "react";
import "./cabeceraS.css";
import logo from './AHlMkI8OP8logo.png'
import { ReactContext } from "../../Context/ReactContext";

function HeaderTop() {
  const { searchValue, setSearchValue } = React.useContext(ReactContext);

  return (   
    <header className="titleTop">
      <div className="elementsTop">
        <img src={logo} className="imageHeader" />	
        <div className="header-content-wrapper">
				  <h1 className="header-title">Xtrapps</h1>
				  <p className="header-subtitle">Number #1 alternative free store where you can download hack for nearly any game.</p>
        </div>
      </div>

    </header>
  );
}
export { HeaderTop };

//Se coloca como event dentro de la capsula para dar la propiedad de evento.
